import {
  StyleParamType,
  wixColorParam,
  createStylesParams,
  wixFontParam,
  IWixStyleFont,
} from '@wix/tpa-settings';
import { IChallengeStyles } from './Settings/challengeStyles/challengeStyles.types';
import { challengeStyles as mobileStyles } from './Settings.mobile/challengeStyles';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

export const customCssVars: CustomCssVarsFn = ({ styleParams }) => {
  const booleans = styleParams.booleans;
  return Object.keys(booleans).reduce((result, key) => {
    result[key] = booleans[key] ? 'unset' : 'none';
    return result;
  }, {} as Record<string, string>);
};

export const fontValueFix = (fontFn, textPresets): IWixStyleFont => {
  const { value: _, ...font } = fontFn({ textPresets });

  return {
    ...font,
  } as IWixStyleFont;
};

const stylesParams = createStylesParams<IChallengeStyles>({
  appBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  badgesBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  badgesTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  buttonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-2'),
  },
  buttonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonHoverBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  buttonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  buttonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  cardSidesPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 32,
  },
  cardTopBottomPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 32,
  },
  cardBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  cardBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  cardBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  cardCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  titleMaxWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  dividerLength: {
    type: StyleParamType.Number,
    getDefaultValue: () => 12.5,
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  nameFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 24,
        }),
        textPresets,
      );
    },
  },
  nameFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  participantsAndDurationFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  participantsAndDurationFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  priceFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  priceFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  imageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  imageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  imageBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  imageBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  imageCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  imageInnerPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sideBySideProportions: {
    type: StyleParamType.Number,
    getDefaultValue: () => 50,
  },
  titleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 32,
        }),
        textPresets,
      );
    },
  },
  titleFontColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  categoriesTabsFontTabs: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 16,
        }),
        textPresets,
      );
    },
  },
  categoriesTabsFontColorTabs: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  categoriesTabsDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2), // 20%
  },
  categoriesTabsSelectedUnderlineColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  opgDisplayImage: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayDuration: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayParticipants: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayDivider: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDisplayPrice: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return true;
    },
  },
  opgDpListTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue() {
      return false;
    },
  },
  opgCardSpacing: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 32;
    },
  },
  opgSideCardsVerticalSpacing: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 32;
    },
  },
  opgItemsPerRow: {
    type: StyleParamType.Number,
    getDefaultValue() {
      return 3;
    },
  },
  ...mobileStyles,
});

export default stylesParams;
export type IChallengeStylesKeys = keyof IChallengeStyles;
export type IChallengeListStylesVars = `--${IChallengeStylesKeys}`;
